






















import Vue from 'vue';
import SectorsOfCareUtils, { SectorOfCare } from '@/utils/SectorsOfCareUtils';
import { locales } from '@/i18n';

export default Vue.extend({
  computed: {
    table() {
      return {
        items: this.items,
        headers: [
          {
            text: this.$i18n.tc('sectorofcare.key'),
            value: 'key',
          },
          {
            text: this.$i18n.tc('sectorofcare.province'),
            value: 'province.name',
          },
          {
            text: this.$i18n.tc('sectorofcare.name_short'),
            value: 'name_short',
          },
          {
            text: this.$i18n.tc('sectorofcare.name_long'),
            value: 'name_long',
          },
          {
            text: '',
            value: 'actions',
          },
        ],
      };
    },

    locales() {
      return locales;
    },
  },

  data: (): {
    items?: SectorOfCare[];
  } => ({
    items: undefined,
  }),

  async mounted() {
    try {
      this.items = await SectorsOfCareUtils.sectorOfCare.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    async handleEdit(item: SectorOfCare) {
      await this.$router.push({
        name: 'sectorofcare-edit',
        params: { id: item.id.toString() },
      });
    },

    async handleCreate() {
      await this.$router.push({
        name: 'sectorofcare-create',
      });
    },
  },
});
